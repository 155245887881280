/**
 * 多数のコンポーネント、moduleで共通して使用することが見込まれる関数を
 * 一か所で管理するためのJSファイル
 */

/**
 * 指定秒数待機するPromiseを作成します
 * @param {number} secs スクロール時間のミリ秒です。
 * @returns {Promise}
 */
 export function sleep(secs) {
    return new Promise(function(resolve){
        setTimeout(() => resolve(), secs)
    })
}

/**
 * Storeから取得してきたデータのうちKeysに含まれるものをsetterつきのcomputedデータとして扱えるよう変換する
 * @param {Array} keys
 */
 export function mapComputedProperties(keys) {
    const map = []
    keys.forEach(key => {
        map[key] = {
            get(){
                return this.data[key]
            },
            set(newValue){
                this.update({
                    [key]:newValue
                })
            }
        }
    })
    return map
}
